<template>
  <div class="mt-2">
    <edition-artist-names :edition="edition"></edition-artist-names>

    <div class="xl-font mt-2">
      <strong>{{ edition.name }}</strong>
    </div>

    <!-- Don't show in no market version yet -->
    <template v-if="$remoteConfig.version != 'nomarket'">
    <div class="mt-2" v-if="edition && !edition.tokenId">
        <span class="lg-font"><price-in-eth :value="edition.priceInEther | to4Dp"></price-in-eth></span>
        <span class="pl-2"><u-s-d-price :price-in-ether="edition.priceInEther"></u-s-d-price></span>
    </div>

    <div class="mt-2">
      <slot></slot>
    </div>
    </template>

    <div class="bg-light mt-4 px-3 py-2">
      <div class="mb-2">Edition</div>
      <div class="row mb-2" v-if="edition && !edition.tokenId">
        <div class="col" v-if="edition.totalAvailable > 1">
          1 of {{ edition.totalAvailable }}
        </div>
        <div class="col" :class="{'text-right': edition.totalAvailable > 1}">
          <availability :edition="edition"></availability>
        </div>
      </div>

      <div class="row small mt-2 mb-2" v-if="edition && edition.tokenId">
          <div class="col">
            <token-id-badge :token-id="edition.tokenId"></token-id-badge>
          </div>
          <div class="col text-right">
            <x-of-x-badge :edition="edition"></x-of-x-badge>
          </div>
      </div>
    </div>

    <div class="row mt-4" v-if="edition && !edition.tokenId">
      <div class="col-6 text-left align-middle">
        <twitter-button :edition="edition"></twitter-button>
      </div>
      <div class="col-6 text-right pt-1">
        <LikeIconButton :edition-number="edition.edition"></LikeIconButton>
      </div>
    </div>

    <div class="mt-4 sans-serif">
      <div class="mb-2">Artwork description</div>
      <div class="text-muted small">
        <span v-for="(line, index) in edition.description.split('\n')" :key="index">{{line}}<br></span>
      </div>
    </div>

    <div class="mt-4 sans-serif" v-if="edition.owner">
      <div class="mb-2">Owner</div>
      <clickable-address :eth-address="edition.owner"></clickable-address>
    </div>

    <div class="mt-4 sans-serif">
      <div class="mb-2">Tags</div>
      <metadata-attributes :edition="edition"></metadata-attributes>
    </div>

    <div class="mt-4 sans-serif">
      <div class="mb-2">Additional detail</div>
      <div class="text-muted small"><image-metadata :edition="edition"></image-metadata></div>
      <div class="small"><erc721-badge></erc721-badge></div>
      <div class="small"><ipfs-badge :edition="edition"></ipfs-badge></div>
      <div class="small"><birth-transaction-badge :edition="edition"></birth-transaction-badge></div>
      <div class="small"><purchase-transaction-badge :token-id="edition.tokenId" v-if="edition && edition.tokenId"></purchase-transaction-badge></div>
      <div class="text-muted small"><creative-challenge-label :attributes="edition.attributes"></creative-challenge-label></div>
    </div>

  </div>
</template>

<script>
  import Availability from '../v2/Availability';
  import CreativeChallengeLabel from '../../components/generic/CreativeChallengeLabel';
  import PriceInEth from '../generic/PriceInEth';
  import MetadataAttributes from '../v2/MetadataAttributes';
  import USDPrice from '../generic/USDPrice';
  import IpfsBadge from '../badges/IpfsBadge';
  import Erc721Badge from '../badges/ERC721Badge';
  import BirthTransactionBadge from '../badges/BirthTransactionBadge';
  import TokenIdBadge from '../badges/TokenIdBadge';
  import XOfXBadge from '../badges/XOfXBadge';
  import PurchaseTransactionBadge from '../badges/PurchaseTransactionBadge';
  import LikeIconButton from '../likes/LikeIconButton';
  import TwitterButton from '../generic/TwitterButton';
  import ImageMetadata from '../generic/ImageMetadata';
  import EditionArtistNames from './EditionArtistNames';
  import ClickableAddress from "../generic/ClickableAddress";

  export default {
    name: 'edition-card',
    props: ['edition'],
    components: {
      ClickableAddress,
      EditionArtistNames,
      ImageMetadata,
      TwitterButton,
      PurchaseTransactionBadge,
      XOfXBadge,
      TokenIdBadge,
      BirthTransactionBadge,
      Erc721Badge,
      IpfsBadge,
      Availability,
      CreativeChallengeLabel,
      PriceInEth,
      MetadataAttributes,
      USDPrice,
      LikeIconButton
    },

  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';
  @import '../../ko-card.scss';

  .xl-font {
    font-size: 1.75rem;
  }

  .lg-font {
    font-size: 1.5rem;
  }
</style>
