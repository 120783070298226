<template>
  <div>
    <loading-section v-if="!edition" :page="PAGES.CONFIRM_PURCHASE"></loading-section>

    <div class="row editions-wrap">

      <div class="col-sm-5 col-lg-5 offset-sm-1 offset-lg-2 mb-5">
        <div class="card" v-if="edition && edition.active">
          <edition-asset :edition="edition" :id="edition.edition" :full="true">
          </edition-asset>
        </div>
      </div>

      <div class="col-sm-5 col-lg-3 mb-5" v-if="edition && edition.active">

        <edition-card :edition="edition">
          <PurchaseFlow :edition="edition" v-on:retry-purchase="retryPurchase"></PurchaseFlow>

          <ConfirmTermsAndPurchase :edition="edition" v-on:purchase-triggered="completePurchase"></ConfirmTermsAndPurchase>

          <!--<EditionSoldOut :edition="edition"></EditionSoldOut>-->

          <div v-if="canProceedWithPurchase">
            <place-edition-bid :edition="edition" :inline="true"></place-edition-bid>
          </div>
        </edition-card>

<!--        <EditionNotOnSaleYet :edition="edition">-->
<!--        </EditionNotOnSaleYet>-->

        <edition-history-event-list :edition="edition"></edition-history-event-list>

      </div>

    </div>

    <other-artist-editions :edition="edition"></other-artist-editions>

  </div>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import * as _ from 'lodash';
  import * as actions from '../store/actions';
  import {PAGES} from '../store/loadingPageState';
  import PlaceEditionBid from '../components/auction/PlaceEditionBid';
  import LikeIconButton from '../components/likes/LikeIconButton';
  import PurchaseFlow from '../components/purhcase/PurchaseFlow';
  import LoadingSection from '../components/generic/LoadingSection';
  import EditionCard from '../components/cards/EditionCard';
  import EditionImage from '../components/generic/EditionImage';
  import EditionSoldOut from '../components/purhcase/EditionSoldOut';
  import EditionNotOnSaleYet from '../components/purhcase/EditionNotOnSaleYet';
  import AccountNotFound from '../components/account/AccountNotFound';
  import ConfirmTermsAndPurchase from '../components/purhcase/ConfirmTermsAndPurchase';
  import GalleryCard from '../components/cards/GalleryCard';
  import OtherArtistEditions from '../components/purhcase/OtherArtistEditions';
  import EditionHistoryEventList from '../components/cards/EditionHistoryEventList';
  import EditionAsset from '../components/generic/EditionAsset';

  export default {
    name: 'confirmPurchase',
    components: {
      EditionHistoryEventList,
      EditionAsset,
      OtherArtistEditions,
      GalleryCard,
      EditionSoldOut,
      LikeIconButton,
      EditionImage,
      EditionCard,
      PlaceEditionBid,
      LoadingSection,
      PurchaseFlow,
      AccountNotFound,
      EditionNotOnSaleYet,
      ConfirmTermsAndPurchase
    },
    metaInfo() {
      if (this.edition) {
        const content = this.edition.artist ? `${this.edition.name} by ${this.edition.artist.name}` : '';
        return {
          title: content,
          meta: [
            {property: 'title', content: content},
            {property: 'url', content: `https://notreal.ai/edition/${this.edition.edition}`},
            {property: 'og:type', content: 'website'},
            {property: 'og:title', content: content},
            {property: 'og:url', content: `https://notreal.ai/edition/${this.edition.edition}`},
            {property: 'og:image', content: this.edition.lowResImg},
            {property: 'og:description', content: this.edition.description},
            {name: 'twitter:card', content: 'summary_large_image'},
            {name: 'twitter:image', content: this.edition.lowResImg},
            {name: 'twitter:title', content: this.edition.name},
            {name: 'twitter:description', content: this.edition.description},
            {name: 'twitter:site', content: '@NotRealAI'}
          ]
        };
      }
    },
    data() {
      return {
        PAGES: PAGES
      };
    },
    computed: {
      ...mapState([
        'account',
        'editionLookupService'
      ]),
      ...mapGetters('kodaV2', [
        'findEdition',
        'isStartDateInTheFuture'
      ]),
      edition() {
        return this.findEdition(this.$route.params.editionNumber);
      },
      canProceedWithPurchase() {
        const hasEditionLeftToPurchase = _.toNumber(this.edition.totalAvailable) - _.toNumber(this.edition.totalSupply) > 0;
        console.log(hasEditionLeftToPurchase, this.isStartDateInTheFuture(this.edition.startDate))

        return hasEditionLeftToPurchase && !this.isStartDateInTheFuture(this.edition.startDate);
      },
    },
    methods: {
      completePurchase() {
        if (!this.account) {
          this.$store.dispatch(actions.WALLET_CONNECT_LOGIN);
          return;
        }

        this.$ga.event('purchase-flow', 'buy-now', 'buy-now-web3-eth');

        this.$store.dispatch(`purchase/${actions.PURCHASE_EDITION}`, {
          edition: this.edition,
          account: this.account
        });
      },
      retryPurchase: function () {

        this.$ga.event('purchase-flow', 'reset-purchase');

        this.$store.dispatch(`purchase/${actions.RESET_PURCHASE_STATE}`, {
          edition: this.edition,
          account: this.account
        });
      }
    },
    created() {
      this.$store.dispatch(`loading/${actions.LOADING_STARTED}`, PAGES.CONFIRM_PURCHASE);

      // Checks to see if refreshed and txs stuck in the flow
      this.$store.watch(
        () => this.$store.state.web3,
        () => {
          this.$store.dispatch(`purchase/${actions.CHECK_IN_FLIGHT_TRANSACTIONS}`, {
            edition: this.edition,
            account: this.account
          });
        }
      );

      /////////
      // API //
      /////////

      const loadApiData = () => {
        this.$store.dispatch(`kodaV2/${actions.LOAD_INDIVIDUAL_EDITION}`, {editionNumber: this.$route.params.editionNumber})
          .finally(() => {
            this.$store.dispatch(`loading/${actions.LOADING_FINISHED}`, PAGES.CONFIRM_PURCHASE);
          });
        this.$store.dispatch(`kodaV2/${actions.LOAD_EDITIONS_PURCHASED_BY_ACCOUNT}`, {account: this.account});
      };

      this.$store.watch(
        () => this.editionLookupService.currentNetworkId,
        () => loadApiData()
      );

      if (this.editionLookupService.currentNetworkId) {
        loadApiData();
      }

    }
  };
</script>

<style scoped lang="scss">
  @import '../ko-colours.scss';
  @import '../ko-card-flex.scss';

  a {
    text-decoration: none;
  }

</style>
