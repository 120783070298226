<template>
  <div v-if="edition && otherEditions.length > 0">
    <div class="row mt-5 mb-3">
      <div class="col offset-sm-1 offset-lg-2">
        <h4>More from <span>{{ (findArtistsForAddress(edition.artistAccount) || {name: 'this artist'} ).name }}</span>
        </h4>
      </div>
    </div>

    <div class="row editions-wrap mb-5">
      <div class="offset-sm-1 offset-lg-2 col-sm-3 mt-4">
        <gallery-card :edition="otherEditions[0]"></gallery-card>
      </div>
      <div class="col-sm-3 mt-4">
        <gallery-card :edition="otherEditions[1]" v-if="otherEditions.length > 1"></gallery-card>
      </div>
      <div class="col-sm-3 mt-4">
        <gallery-card :edition="otherEditions[2]" v-if="otherEditions.length > 2"></gallery-card>
      </div>
    </div>

    <div class="row editions-wrap mt-5 mb-5">
      <div class="col mx-auto text-center">
        <router-link :to="{ name: 'artist', params: { artistAccount: edition.artistAccount } }"
                     class="btn btn-outline-primary">
          View all artworks
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
  import GalleryCard from '../cards/GalleryCard';
  import {mapGetters, mapState} from 'vuex';
  import * as _ from 'lodash';

  export default {
    name: 'other-artist-editions',
    components: {GalleryCard},
    props: {
      edition: {
        type: Object
      }
    },
    data() {
      return {
        otherEditions: []
      };
    },
    computed: {
      ...mapState([
        'editionLookupService'
      ]),
      ...mapGetters([
        'findArtistsForAddress'
      ]),
    },
    watch: {
      edition: function (newValue) {
        if (newValue) {
          this.editionLookupService.getGalleryEditions({
            limit: 10,
            //null, ultrarare, rare, common = scarcity_filter
            // orderBy: 'priceInEther',
            // order: 'desc',
            artist_filter: newValue.artistAccount
          })
            .then((results) => {
              if (results.success) {
                const {data, resultsSize} = results;
                const worksMinusCurrent = _.remove(data, function (edition) {
                  return edition.edition !== newValue.edition;
                });
                this.otherEditions = _.shuffle(worksMinusCurrent);
              }
            });
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';
  @import '../../ko-card-flex.scss';

</style>


