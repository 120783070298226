<template>
  <div v-if="hasMetaData" v-b-tooltip.hover title="Full size image can be downloaded once purchased" class="sans-serif">
      {{fileDimension}} - {{fileSize}}
  </div>
</template>

<script>
   import * as _ from 'lodash';
   import { VBTooltip } from 'bootstrap-vue';

   export default {
    name: 'imageMetadata',
    props: ['edition'],
    directives: {'b-tooltip': VBTooltip},
    methods: {
      formatBytes(bytes) {
        const k = 1024;
        const sizes = ['bytes', 'kb', 'mb'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i];
      }
    },
    computed: {
      hasMetaData() {
        return _.has(this.edition, 'image_metadata["Content-Type"]');
      },
      fileDimension() {
        if (this.edition && _.has(this.edition, 'image_metadata["Content-Type"]')) {
          const contentType = this.edition.image_metadata["Content-Type"];
          return `${this.edition.image_metadata.PixelWidth} x ${this.edition.image_metadata.PixelHeight} px (${contentType})`;
        }
        return '';
      },
      fileSize() {
        if (this.edition && _.has(this.edition, 'image_metadata["Content-Length"]')) {
          const contentLength = this.edition.image_metadata["Content-Length"];
          return this.formatBytes(contentLength);
        }
        return '';
      }
    }
  };
</script>

<style scoped lang="scss">
</style>
