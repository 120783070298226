<template>

  <div class="pt-4" v-if="!account">
    <account-not-found-msg class="text-center"></account-not-found-msg>
    <p class="text-mute pt-3">
      You need an Web 3 enabled wallet like
      <a href="https://metamask.co" target="_blank">MetaMask</a>,
      <a href="https://trustwalletapp.com" target="_blank">Trust Wallet</a> or
      <!--<a href="https://metamask.co" target="_blank">Portis</a> or-->
      <a href="https://metamask.co" target="_blank">Fortmatic</a> to buy this item.
    </p>
  </div>

</template>

<script>
  import {mapState} from 'vuex';
  import AccountNotFoundMsg from './AccountNotFoundMsg';

  export default {
    name: 'AccountNotFound',
    components: {AccountNotFoundMsg},
    computed: {
      ...mapState([
        'account',
      ]),
    }
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours';
  @import '../../ko-card-flex';
</style>

