<template>
  <div class="mt-4 bg-white sans-serif" v-if="editionHistory.length > 0">

    <div class="mb-2">Recent History</div>

    <div v-for="event in limitBy(orderBy(editionHistory, 'blockNumber', -1), 5)" :key="event.id">
      <div class="row">
        <div class="col"><code class="small">{{event.event | humanize}}</code></div>
        <div class="col text-right text-muted">
          <small v-if="event.blockTimestamp">
            {{ event.blockTimestamp | moment('from')}}
          </small>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <span class="small" v-if="event._args._amount">
            <price-in-eth :value="event._args._amount | toEth"></price-in-eth>
            <u-s-d-price-converter
              :price-in-wei="event._args._amount"
              :usd-exchange-rate="event.exchangeRate.usd">
            </u-s-d-price-converter>
          </span>
          <span class="small" v-if="event._args._priceInWei">
            <price-in-eth :value="event._args._priceInWei | toEth"></price-in-eth>
            <u-s-d-price-converter
              :price-in-wei="event._args._priceInWei"
              :usd-exchange-rate="event.exchangeRate.usd">
            </u-s-d-price-converter>
          </span>
        </div>
      </div>

      <div v-if="event._args._bidder" class="row mb-2 text-muted">
        <div class="col">
          <clickable-address :eth-address="event._args._bidder" class=""></clickable-address>
        </div>
        <div class="col text-right">
          <span v-if="event._args._tokenId" class="">
            <router-link :to="{ name: 'edition-token', params: { tokenId: event._args._tokenId.toString() }}" class="badge badge-primary">
              {{ event._args._tokenId.toString() }}
            </router-link>
          </span>
        </div>
      </div>

      <div v-if="event._args._buyer" class="row mb-2 text-muted">
        <div class="col">
          <clickable-address :eth-address="event._args._buyer" class=""></clickable-address>
        </div>
        <div class="col text-right">
          <span v-if="event._args._tokenId" class="">
            <router-link :to="{ name: 'edition-token', params: { tokenId: event._args._tokenId.toString() }}" class="badge badge-primary">
              {{ event._args._tokenId.toString() }}
            </router-link>
          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import ClickableAddress from '../generic/ClickableAddress';
  import PriceInEth from '../generic/PriceInEth';
  import USDPrice from '../generic/USDPrice';
  import ClickableTransaction from '../generic/ClickableTransaction';
  import ViewTransactionDetails from '../generic/ViewTransactionDetails';
  import USDPriceConverter from '../generic/USDPriceConverter';
  import {mapGetters, mapState} from 'vuex';

  export default {
    name: 'EditionHistoryEventList',
    components: {
      ViewTransactionDetails,
      ClickableTransaction,
      PriceInEth,
      ClickableAddress,
      USDPriceConverter,
      USDPrice
    },
    props: {
      edition: {
        type: Object
      }
    },
    data() {
      return {
        editionHistory: []
      };
    },
    computed: {
      ...mapState([
        'eventService',
      ]),
    },
    methods: {},
    created() {

      const loadData = () => {
        this.eventService
          .loadEditionHistoryEvents(this.edition)
          .then((editionHistory) => {
            this.editionHistory = editionHistory;
          });
      };

      this.$store.watch(
        () => this.eventService.currentNetworkId,
        () => loadData()
      );

      if (this.eventService.currentNetworkId) {
        loadData();
      }
    },
  };
</script>

<style scoped lang="scss">
</style>
