<template>
  <span>
    <span v-for="(tag, index) in getAttributesToDisplay" :key="index">
      <span class="tag hand-pointer mr-1" v-on:click="deepLinkTagToGallery(tag)">
        <u>{{tag}}</u> &nbsp;&nbsp;
      </span>
    </span>
    <rarity-indicator :total-available="edition.totalAvailable"
                      v-on:click="(value) => deepLinkRarityToGallery(value)">
    </rarity-indicator>
  </span>
</template>

<script>
  import * as _ from 'lodash';
  import RarityIndicator from './RarityIndicator';

  export default {
    name: 'metadataAttributes',
    components: {
      RarityIndicator,
    },
    props: ['edition'],
    computed:{
      getAttributesToDisplay() {
        return _.get(this.edition.attributes, 'tags', []);
      },
    },
    methods: {
      deepLinkTagToGallery(tag) {
        this.$router.push({name: 'gallery', query: {tag: tag}});
      },
      deepLinkRarityToGallery(rarity) {
        this.$router.push({name: 'gallery', query: {rarity: rarity}});
      }
    }
  };
</script>

<style scoped lang="scss">

</style>
