<template>
     <span class="">
       <a href="https://erc721validator.org/?address=0xfbeef911dc5821886e1dda71586d90ed28174b7d" target="_blank" @click="onClick">
         <!--<font-awesome-icon :icon="['fab', 'ethereum']"></font-awesome-icon> --> ERC721 standard
       </a>
    </span>
</template>

<script>

  export default {
    name: 'ERC721Badge',
    components: {
    },
    methods: {
      onClick() {
        this.$ga.event('badges', 'erc721-link', 'open-erc721-site');
      }
    }
  };
</script>

<style scoped lang="scss">
  a {
    color: #6c757d !important;
  }
</style>
