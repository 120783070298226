<template>
  <div v-if="edition
            && isNotSoldOut
            && !isStartDateInTheFuture(edition.startDate)
            && !isPurchaseFailed(edition.edition, account)">

    <div v-if="showTermsAndConditions">

      <button type="button" class="btn btn-primary text-white btn-block"
              v-on:click="$emit('purchase-triggered')"
              :disabled="isPurchaseTriggered(edition.edition, account)"
              v-if="isNotSoldOut || !isPurchaseSuccessful(edition.edition, account)">
        Buy now (ETH)
      </button>

      <buy-edition-nifty-gateway class="mt-3" :edition="edition">
      </buy-edition-nifty-gateway>

    </div>

    <!-- Purchased and show ability to re-buy -->
    <div class="py-2 sans-serif" v-if="!overrideAlreadyPurchased && alreadyPurchasedEdition(edition.edition)">

      <div class="mb-2">
        Looks like you already own this<br/>
        <span class="btn-link hand-pointer" v-on:click="overridePurchase">purchase another one?</span>
      </div>
    </div>

  </div>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import * as actions from '../../store/actions';
  import BuyEditionNiftyGateway from './BuyEditionNiftyGateway';

  export default {
    name: 'ConfirmTermsAndPurchase',
    components: {
      BuyEditionNiftyGateway
    },
    props: {
      edition: {
        type: Object
      }
    },
    data() {
      return {
        overrideAlreadyPurchased: false
      };
    },
    computed: {
      ...mapState([
        'account',
        'etherscanBase',
      ]),
      ...mapGetters('kodaV2', [
        'alreadyPurchasedEdition',
        'isStartDateInTheFuture',
      ]),
      ...mapState('purchase', [
        'purchaseState'
      ]),
      ...mapGetters('purchase', [
        'editionPurchaseState',
        'isPurchaseTriggered',
        'isPurchaseStarted',
        'isPurchaseSuccessful',
        'isPurchaseFailed',
      ]),
      isNotSoldOut() {
        return this.edition.totalAvailable - this.edition.totalSupply > 0;
      },
      showTermsAndConditions() {

        // If purchase inflight or finished, dont show
        if (this.isPurchaseTriggered(this.edition.edition, this.account) ||
          this.isPurchaseSuccessful(this.edition.edition, this.account) ||
          this.isPurchaseStarted(this.edition.edition, this.account)) {
          return false;
        }

        // Override and force show T&Cs with purchase button
        if (this.overrideAlreadyPurchased) {
          return true;
        }

        // By default is already purchased dont show T&Cs and purchase button
        if (this.alreadyPurchasedEdition(this.edition.edition)) {
          return false;
        }

        // By default show if unless the purchase flow is in flight
        return !this.editionPurchaseState(this.edition.edition);
      }
    },
    methods: {
      overridePurchase() {
        this.overrideAlreadyPurchased = true;
        this.$store.dispatch(`purchase/${actions.RESET_PURCHASE_STATE}`, {
          edition: this.edition,
          account: this.account
        });
      }
    }
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';
  @import '../../ko-card-flex.scss';

  .form-check {
    padding-left: 0;
  }

  .btn {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
  }
</style>


