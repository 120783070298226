<template>
  <div class="pt-0 pl-4 pr-4 text-center" v-if="isSoldOut">
    <p>
      Checkout more artwork from
      <router-link :to="{ name: 'artist', params: { artistAccount: edition.artistAccount } }">
        {{ (findArtistsForAddress(edition.artistAccount) || {name: 'this artist'} ).name }}
      </router-link>
    </p>
  </div>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';

  export default {
    name: 'EditionSoldOut',
    components: {},
    props: {
      edition: {
        type: Object
      }
    },
    computed: {
      ...mapGetters([
        'findArtistsForAddress'
      ]),
      isSoldOut() {
        return this.edition.totalAvailable - this.edition.totalSupply <= 0;
      },
    }
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';
  @import '../../ko-card-flex.scss';

</style>


