<template>
     <span class="" v-if="edition.tokenUri">
       <a :href="edition.tokenUri" @click="onClick" target="_blank">
         <!--<font-awesome-icon :icon="['fas', 'cube']"></font-awesome-icon> --> IPFS hosted
       </a>
    </span>
</template>

<script>
  export default {
    name: 'ipfsBadge',
    props: ['edition'],
    components: {
    },
    methods: {
      onClick() {
        this.$ga.event('badges', 'ipfs-link', 'open-ipfs-data');
      }
    }
  };
</script>

<style scoped lang="scss">
  a {
    color: #6c757d !important;
  }
</style>
