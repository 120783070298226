import { render, staticRenderFns } from "./ERC721Badge.vue?vue&type=template&id=c7067fda&scoped=true&"
import script from "./ERC721Badge.vue?vue&type=script&lang=js&"
export * from "./ERC721Badge.vue?vue&type=script&lang=js&"
import style0 from "./ERC721Badge.vue?vue&type=style&index=0&id=c7067fda&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7067fda",
  null
  
)

export default component.exports