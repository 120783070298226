<template>
  <span class="tag hand-pointer"
        @click="clicked($event)">
    <u>{{label}}</u>
  </span>
</template>

<script>
  import TagPill from './TagPill';

  export default {
    name: 'rarityIndicator',
    components: {TagPill},
    props: ['totalAvailable'],
    computed: {
      label() {
        if (this.totalAvailable === 1) {
          return 'ultrarare';
        }
        if (this.totalAvailable > 1 && this.totalAvailable <= 5) {
          return 'rare';
        }
        return 'common';
      }
    },
    methods: {
      clicked() {
        this.$emit('click', this.label);
      },
    }
  };
</script>

<style scoped>
</style>
