<template>
  <b-badge class="tag-pill" variant="light"
           @click="clicked($event)">
    <slot></slot>
  </b-badge>
</template>

<script>
  import {BBadge} from 'bootstrap-vue';

  export default {
    name: 'tag-pill',
    components: {BBadge},
    props: ['edition'],
    methods: {
      clicked($event) {
        this.$emit('click', $event);
      }
    }
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';
  @import '../../../node_modules/bootstrap/scss/bootstrap.scss';

  .tag-pill {
    font-family: 'Roboto', sans-serif;
    padding: 10px;
    margin: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: #dee2e6;
    background-color: #f8f9fa;
    font-size: 0.85rem;
    color: $secondary;
  }

  .tag-pill:hover {
    background-color: #f8f9fa;
    border-color: $secondary;
    border-style: solid;
    border-width: 2px;
  }

  .tag-pill:hover {
    padding: 10px;
    margin: 10px;
    border-style: solid;
    border-width: 2px;
    background-color: #F8F8FF !important;;
    border-color: $primary !important;
    color: $primary;
    font-size: 0.85rem;
  }

</style>
