<template>
  <div class="sans-serif">

    <!-- Triggered -->
    <div class="py-2" v-if="isPurchaseTriggered(edition.edition, account)">
      <div class="mt-2">
        <img src="../../../public/static/Clock_icn_32px.svg" style="max-height: 25px"/>
        Your purchase has been initiated
      </div>
    </div>

    <!-- Started -->
    <div class="py-2" v-if="isPurchaseStarted(edition.edition, account)">
      <div class="mt-2">
        <img src="../../../public/static/Clock_icn_32px.svg" style="max-height: 25px"/>
        Your purchase is being confirmed
      </div>
      <div class="mt-0">
        <view-transaction-details :transaction="getTransactionForEdition(edition.edition, account)"></view-transaction-details>
      </div>
    </div>

    <!-- Success -->
    <div class="py-2" v-if="isPurchaseSuccessful(edition.edition, account)">
      <div class="mt-2">
        <img src="../../../public/static/Success_icn_32px.svg" style="max-height: 25px"/>
        Your purchase was successful
      </div>
      <div class="mt-0">
        <view-transaction-details :transaction="getTransactionForEdition(edition.edition, account)"></view-transaction-details>
      </div>
    </div>

    <!-- Failure/Unnot -->
    <div class="py-2" v-if="isPurchaseFailed(edition.edition, account)">

      <div class="mt-2">
        <img src="../../../public/static/Fail_icn_32px.svg" style="max-height: 25px"/>
        It looks like the purchase didn't complete successfully.
      </div>

      <div class="mt-2">
        Please check your <a :href="etherscanAccountPage" target="_blank">account</a>
        <span v-if="getTransactionForEdition(edition.edition, account)">
          and view the transaction <view-transaction-details :transaction="getTransactionForEdition(edition.edition, account)"></view-transaction-details>
        </span>
        before trying again.
      </div>

      <div class="mt-2">
        <button type="button" v-on:click="$emit('retry-purchase')" class="btn btn-outline-primary">
          Retry?
        </button>
      </div>

    </div>
  </div>

</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import ClickableTransaction from "../generic/ClickableTransaction";
  import ViewTransactionDetails from "../generic/ViewTransactionDetails";

  export default {
    name: 'PurchaseFlow',
    components: {
      ViewTransactionDetails,
      ClickableTransaction
    },
    props: {
      edition: {
        type: Object
      }
    },
    data() {
      return {
        confirm_terms: false
      };
    },
    computed: {
      ...mapState([
        'account',
        'etherscanBase',
      ]),
      ...mapGetters('purchase', [
        'editionPurchaseState',
        'isPurchaseTriggered',
        'isPurchaseStarted',
        'isPurchaseSuccessful',
        'isPurchaseFailed',
        'getTransactionForEdition',
      ]),
      etherscanAccountPage() {
        return `${this.etherscanBase}/address/${this.account}`;
      }
    }
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';
  @import '../../ko-card-flex.scss';

</style>


