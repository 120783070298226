<template>
     <span class="" v-if="edition">
        <a :href="link" target="_blank" @click="onClick">
         <!--<font-awesome-icon :icon="['fas', 'bolt']"></font-awesome-icon> --> Creation
        </a>
    </span>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';

  export default {
    components: {
    },
    name: 'BirthTransactionBadge',
    props: ['edition'],
    computed: {
      ...mapState([
        'etherscanBase',
        'eventService',
      ]),
    },
    data() {
      return {
        link: null
      };
    },
    methods: {
      onClick() {
        this.$ga.event('badges', 'birth-transaction', 'open-birth-transaction');
      }
    },
    created() {
      this.eventService
        .findBirthTransaction(this.edition)
        .then((data) => {
          if (!data) { return }
          this.link = `${this.etherscanBase}/tx/${data.transactionHash}`;
        });
    }
  };
</script>

<style scoped lang="scss">
  a {
    color: #6c757d !important;
  }
</style>
