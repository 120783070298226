<template>
     <span class="" v-if="tokenId && link">
        <a :href="link" target="_blank" @click="onClick">
         <!--<font-awesome-icon :icon="['fas', 'receipt']"></font-awesome-icon> --> Receipt
        </a>
    </span>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';

  export default {
    components: {
    },
    name: 'PurchaseTransactionBadge',
    props: ['tokenId'],
    computed: {
      ...mapState([
        'eventService',
        'etherscanBase',
      ]),
    },
    data() {
      return {
        link: null
      };
    },
    methods: {
      onClick() {
        this.$ga.event('badges', 'purchase-transaction', 'open-purchase-transaction');
      }
    },
    created() {
      this.eventService
        .findPurchaseTransaction(this.tokenId)
        .then((data) => {
          if (!data) {
            return null;
          }
          this.link = `${this.etherscanBase}/tx/${data.transactionHash}`;
        });
    }
  };
</script>

<style scoped lang="scss">
  a {
    color: #6c757d !important;
  }
</style>
