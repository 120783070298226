import { render, staticRenderFns } from "./ImageMetadata.vue?vue&type=template&id=f8dd7698&scoped=true&"
import script from "./ImageMetadata.vue?vue&type=script&lang=js&"
export * from "./ImageMetadata.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8dd7698",
  null
  
)

export default component.exports